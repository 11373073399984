<template>
	<a-drawer :zIndex="1000" :title="dialog.type === 'add' ? 'Add New Elevation' : 'Edit Elevation'" :width="'50%'"
		@close="onClose" :maskClosable="false" :visible="dialog.show"
		:drawerStyle="{ height: 'calc(100% - 50px)', overflow: 'auto' }" class="elevation-hide-scrollbar">
		<bh-loading :show="loading" />
		<a-form-model ref="newElevation" :model="newElevation" class="h-full dF fC jSB">
			<div class="f1">
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item prop="name" label="Elevation Type" required
							:rules="req('Please fill in the Name')">
							<a-input v-model="newElevation.name" placeholder="Example: A">
							</a-input>
						</a-form-model-item>
					</a-col>

					<a-col :span="12">
						<a-form-model-item label="Tags">
							<a-select v-model="newElevation.tags" mode="tags" style="width: 100%"
								placeholder="Example: Phase 1 \ Hide \ Walkout">
								<a-select-option v-for="tag in tags" :value="tag.name" :key="tag.id">
									{{ tag.name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<!-- removed the bottom linked elevation as its a manual work to connect -->
					<a-col :span="24" v-if="otherElevations.length && false">
						<a-form-model-item prop="name" label="Linked Elevation(s) for Semis/Towns">
							<a-select v-model="newElevation.connected" mode="multiple" style="width: 100%">
								<a-select-option v-for="unit in otherElevations" :value="unit.id" :key="unit.id">
									{{ unit.name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

				</a-row>
				<a-row :gutter="16">
					<a-col :span="8">
						<a-form-model-item label="Elevation Image" required>
							<ImageBox :img="newElevation.image" @callback="selectImg" @formats="formats" />
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item>
							<span slot="label">
								Alternate Elevation Image
								<a-tooltip title="Use this field to show another image for your elevation."
									overlayClassName="change-tooltip-color">
									<a-icon type="question-circle-o" style="color: var(--orange);" />
								</a-tooltip>
							</span>
							<ImageBox :img="newElevation.image2" @callback="selectImg2" @formats="formats" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-tabs hideAdd v-model="activeKey" type="editable-card" @edit="onEditNewElevationPackage">
					<a-tab-pane v-for="pane in newElevationPackages" :tab="pane.name" :key="pane.key"
						:closable="pane.closable">
						<a-row :gutter="16">
							<a-col :span="8">
								<a-form-model-item label="Floor Plan Options" has-feedback
									:validate-status="!pane.name || !pane.name.trim() ? 'error' : ''">
									<a-input v-model="pane.name" placeholder="Floor Plan Options">
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item>
									<template #label>
										<span>Model Code</span>
										<a-tooltip title="Override Model Code value here" overlayClassName="change-tooltip-color">
											<a-icon type="question-circle" style="font-size: 14px" class="ml-2" />
										</a-tooltip>
									</template>
									<a-input v-model="pane.code" placeholder="Model Code">
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Price (Only digits)">
									<a-input-number style="width:100%" v-model="pane.price"
										:formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0"
										placeholder="Price">
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Price Per Sq. Ft.">
									<a-input-number disabled style="width:100%"
										:value="pane.sqft > 0 && pane.price ? (pane.price/(pane.sqft + (pane.sqft2 || 0))).toFixed(2) : 0"
										:formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0"
										placeholder="Price" >
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Stories (Only digits)">
									<a-input-number style="width:100%" v-model="pane.stories"
										:formatter="stories => `${stories}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="1"
										:max="100" placeholder="Stories">
									</a-input-number>
								</a-form-model-item>
							</a-col>

							<a-col :span="8">
								<a-form-model-item label="Garages (Only digits)">
									<a-input-number style="width:100%" v-model="pane.garages"
										:formatter="garages => `${garages}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="1"
										:max="100" placeholder="Garages">
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Square Footage (Only digits)">
									<a-input-number style="width:100%" v-model="pane.sqft"
										:formatter="sqft => `${sqft}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="1"
										placeholder="Sqft">
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Additional Sq. Ft. (Only digits)">
									<a-input-number style="width:100%" v-model="pane.sqft2"
										:formatter="sqft => `${sqft}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0"
										placeholder="Sqft">
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Bedrooms (Only digits)">
									<a-input-number style="width:100%" v-model="pane.beds"
										:formatter="beds => `${beds}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="1"
										:max="100" placeholder="Bedrooms">
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Den/Library (Only digits)">
									<a-input-number style="width:100%" v-model="pane.dens"
										:formatter="dens => `${dens}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0"
										:max="100" placeholder="Dens">
									</a-input-number>
								</a-form-model-item>
							</a-col>

							<a-col :span="8">
								<a-form-model-item label="Bathrooms (Only digits)">
									<a-input-number style="width:100%" v-model="pane.baths"
										:formatter="baths => `${baths}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="1"
										:max="100" placeholder="Bathrooms">
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="8">
								<a-form-model-item label="Powder Rooms (Only digits)">
									<a-input-number style="width:100%" v-model="pane.powderRooms"
										:formatter="powderRooms => `${powderRooms}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
										:min="0" :max="100" placeholder="Powder Rooms">
									</a-input-number>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="Downloadable Floor Plan Brochure"
									style="margin-bottom: 0 !important;">
									<div style="display:flex;">
										<div style="width:70%" class="mr-3">
											<a-input v-model="newElevation.pdf" :disabled="true" :key="newTime" />
										</div>
										<div style="width:30%; margin-top: 3.5px;" class="dF">
											<a-button v-if="newElevation.pdf" @click="newElevation.pdf = ''" type="danger"
												class="mr-2"><a-icon type="delete"></a-icon></a-button>
											<a-button style="background-color:#ECE9F1;" class="f1"
												@click="selectPdf"><a-icon type="plus"></a-icon>ADD PDF</a-button>
										</div>
									</div>
									<small>Maximum upload file size: 25MB</small>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="Features and Finishes" style="margin-bottom: 0 !important;">
									<div style="display:flex">
										<div style="width:70%" class="mr-3">
											<a-input v-model="newElevation.features" disabled />
										</div>
										<div style="width:30%; margin-top: 3.5px;" class="dF">
											<a-button v-if="newElevation.features" @click="newElevation.features = ''"
												type="danger" class="mr-2"><a-icon type="delete"></a-icon></a-button>
											<a-button style="background-color:#ECE9F1;" class="f1"
												@click="selectFeatures"><a-icon type="plus"></a-icon>ADD PDF</a-button>
										</div>
									</div>
									<small>Maximum upload file size: 25MB</small>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
                                <a-form-model-item label="Downloadable Sales Sheet"
                                    style="margin-bottom: 0 !important;">
                                    <div style="display:flex;">
                                        <div style="width:70%" class="mr-3">
                                            <a-input v-model="newElevation.salesSheet" :disabled="true" :key="newTime" />
                                        </div>
                                        <div style="width:30%; margin-top: 3.5px;" class="dF">
                                            <a-button v-if="newElevation.salesSheet" @click="newElevation.salesSheet = ''" type="danger"
                                                class="mr-2"><a-icon type="delete"></a-icon></a-button>
                                            <a-button style="background-color:#ECE9F1;" class="f1"
                                                @click="selectSalesSheet"><a-icon type="plus"></a-icon>ADD PDF</a-button>
                                        </div>
                                    </div>
                                    <small>Maximum upload file size: 25MB</small>
                                </a-form-model-item>
                            </a-col>
							<a-col v-if="dialog.show" :span="24">
								<h6 class="mt-3">Custom Fields</h6>
								<hr>
								<a-row :gutter="16" type="flex" justify="start">
									<a-col :span="8" v-for="field in fields" :key="field.id">
										<a-form-model-item :label="field.name" :required="!!field.required" :rules="req(`Please enter ${field.name}`, !!field.required)">
											<a-input :placeholder="field.defaultAnswer" v-if="field.type == 'text'"
												:default-value="field.defaultAnswer" v-model="pane.other.cf[field.id]"
												@blur="fieldChanged" :key="updateField + field.id" />
											<a-input v-if="field.type == 'number'" type="number" v-model="pane.other.cf[field.id]" :default-value="Number(field.defaultAnswer || 0)" @blur="fieldChanged" :key="updateField + field.id"/>
											<a-date-picker v-else-if="field.type == 'date'" style="width:100%" :default-value="pane.other.cf[field.id] ? moment(pane.other.cf[field.id]) : moment(field.defaultAnswer || new Date())" @change="(e) => applyMulField(pane, e, field.id)"/>
											<template v-else-if="field.type == 'checkbox'">
												<a-checkbox v-for="(option, optionI) in field.options" :value="option" :key="optionI+updateField" @change="(e) => changeCheck(pane, e, field.id)" :checked="option == (pane.other.cf[field.id] || field.defaultAnswer)">{{option}}</a-checkbox>
											</template>
											<template v-if="dialog.type == 'add' || (dialog.type == 'edit' && !pane.id)">
												<a-select @change="(e) => applyMulField(pane, e, field.id)"
													:default-value="field.defaultAnswer"
													:mode="field.multipleAnswer ? 'multiple' : ''"
													v-if="field.type == 'multipleChoice'">
													<a-select-option v-for="(option, optionI) in field.options"
														:key="(option + optionI)" :value="option">{{ option
														}}</a-select-option>
												</a-select>
											</template>
											<template v-else-if="dialog.type == 'edit' && pane.id">
												<a-select :mode="field.multipleAnswer ? 'multiple' : ''"
													v-if="field.type == 'multipleChoice'" v-model="pane.other.cf[field.id]">
													<a-select-option v-for="(option, optionI) in field.options"
														:key="(option + optionI)" :value="option">{{ option
														}}</a-select-option>
												</a-select>
											</template>
										</a-form-model-item>
									</a-col>
								</a-row>

								<a-button class="mb-3" icon="plus" type="primary" @click="addField">ADD CUSTOM
									FIELD</a-button>
							</a-col>
							<a-col :span="24" class="mt-4">
								<h6>Floor Plan</h6>
								<hr>
							</a-col>

							<div class="w-full" style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr));">
								<div class="relative" v-for="(floor, floorI) in pane.floors"
									:key="'flooor ' + pane.name + pane.key + floorI">
									<div v-if="floorI > 0" @click="deleteNewFloor(pane, floorI)" class="mr-3"
										style="position: absolute; right: 0; cursor: pointer; z-index: 1000;">
										<i class="fa fa-minus-circle" style="color:var(--orange); font-size: 20px;" />
									</div>
									<a-col>
										<a-form-model-item :label="floorI == 0 ? 'Basement Name' : 'Floor Name'" required
											:rules="req('Please enter the Floor Name')">
											<a-input v-model="floor.name" placeholder="Example: Unfinished Basement"
												:disabled="floorI == 0 && floor.name == 'N/A' ? true : false">
												<a-tooltip overlayClassName="change-tooltip-color" v-if="floorI == 0" slot="suffix"
													:title="floor.name == 'N/A' ? 'Show Basement' : 'Hide Basement'">
													<a-icon v-if="floor.name == 'N/A'" @click="showFloorplan(pane)"
														type="eye-invisible"
														style="color: rgba(0,0,0,.45); cursor: pointer;" />
													<a-icon v-else type="eye" @click="hideFloorplan(pane)"
														style="color: rgba(0,0,0,.45); cursor: pointer;" />
												</a-tooltip>
											</a-input>
										</a-form-model-item>
									</a-col>
									<a-col>
										<a-form-model-item label="Floor Plan Image" style="margin-bottom: 0 !important;"
											:required="floorI > 0">
											<ImageBox :img="floor.image" @callback="(e) => selectedFloorImage(e, floor)"
												:disabled="floorI == 0 && floor.name == 'N/A' ? true : false" />
										</a-form-model-item>
									</a-col>

									<a-col v-if="pane.floors.length > 1" :span="24">
										<hr>
									</a-col>
								</div>
							</div>

						</a-row>
						<a-button icon="plus" class="mt-3" type="primary" @click="addFloor(pane)">ADD FLOOR</a-button>

					</a-tab-pane>
					<a-button type="primary" slot="tabBarExtraContent" icon="plus" @click="addNewElevationPackage">ADD FLOOR
						PLAN PACKAGE</a-button>
				</a-tabs>
			</div>

			<div :style="{
				position: 'absolute',
				left: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
			}">
				<div class="dF jSB">
					<div>
						<a-button size="large" :disabled="deleting" @click="deleteElevation" v-if="dialog.type === 'edit'"
							type="danger" ghost icon="delete">DELETE ELEVATION</a-button>

					</div>
					<div>
						<a-button class="cancel-button" size="large" :style="{ marginRight: '8px' }" style="width:120px"
							@click="onClose">
							CANCEL
						</a-button>
						<a-button size="large" style="width:120px" type="primary" @click="submitButton">{{ dialog.type ===
							'add' ? 'CREATE' : 'UPDATE' }}</a-button>
					</div>
				</div>

			</div>

		</a-form-model>

	</a-drawer>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import mime from 'mime-types'
import ImageBox from '@/components/models/ImageBox'
import moment from 'moment'

const packageDetails = {
	stories: 1,
	garages: 1,
	sqft: 100,
	sqft2: 0,
	beds: 1,
	dens: 0,
	baths: 1,
	powderRooms: 1,
	finishedBasement: false,
	hasLoft: false,
	price: 0,
	floors: [
		{
			name: '',
			order: -1,
			image: ''
		}
	]
}
export default {
	components: { bhLoading, ImageBox },
	props: {},
	data() {
		return {
			updateField: 0,
			options: [],
			floorMain: {},
			fileType: mime.lookup({ type: String, required: true }),
			deleting: false,
			deletePopup: false,
			newTime: 'SDFXCV####XDVXCVX',
			openNewFloor: '10',
			newTabIndex: 0,
			activeKey: '1',
			uniqueFields: [],
			newElevationPackages: [
				{
					name: 'Standard Floor Plan',
					code: '',
					key: '1',
					closable: false,
					...JSON.parse(JSON.stringify(packageDetails)),
					other: { cf: {} }
				}
			],
			loading: false,
			newElevation: {
				features: '',
				name: '',
				image: '',
				image2: '',
				pdf: '',
				salesSheet: '',
				newTags: [],
				media: {},
				connected: [],
				customFields: [
					{
						key: '',
						value: '',
					}
				],
				tags: [],
			},
			Model: {
				name: ''
			},
		};
	},
	watch: {
		newElevationPackages: {
			handler(val) {
				val.forEach(x => {
					x.floors[0].order = -1
				})
			},
			deep: true
		},
		activeKey(val) {
			this.openNewFloor = val.key + 0
		},
		dialog: {
			handler(val) {
				if (!val.show) {
					this.deleting = false
					this.deletePopup = false
					this.activeKey = '1'
					this.newElevation = {
						name: '',
						image: '',
						image2: '',
						pdf: '',
						features: '',
						salesSheet: '',
						newTags: [],
						customFields: [
							{
								key: '',
								value: '',
							}
						],
					}
					this.Model = {
						name: ''
					}

					this.newElevationPackages = [
						{
							name: 'Standard Floor Plan',
							code: '',
							key: '1',
							closable: false,
							...JSON.parse(JSON.stringify(packageDetails)),
							other: { cf: {} }
						}
					]
				} else if (val.type === 'edit') {
					let { packages, ...elev } = this.$store.state.models.editElevation
					if (elev.tags && elev.tags.length && typeof elev.tags[0] === 'object') {
						elev.tags = elev.tags.map(x => x.name)
					}
					else if (elev.tags && elev.tags.length && typeof elev.tags[0] == 'string') {
						let array = []
						elev.tags.forEach(tag => {
							array.push(this.tags[tag].name)
						})
						elev.tags = array
					}
					if (!elev.connected) elev.connected = []
					if (!elev.salesSheet) elev.salesSheet = ''
					this.newElevation = JSON.parse(JSON.stringify(elev))
					packages = JSON.parse(JSON.stringify(packages))
					packages.forEach((pkg, i) => {
						pkg.key = (i + 1) + ''
						if (!pkg.other) pkg.other = {}
						if (!pkg.other.cf) pkg.other.cf = {}

					})
					this.newElevationPackages = packages
					this.newElevationPackages[0].closable = false

					let newmodel = JSON.parse(JSON.stringify(this.$store.state.models.showingModel))
					this.Model = newmodel
				}
				else if (val.type === 'add') {
					let newmodel = JSON.parse(JSON.stringify(this.$store.state.models.showingModel))
					this.Model = newmodel
					let fields = JSON.parse(JSON.stringify(this.fields))
					this.newElevationPackages.forEach(x => {
						Object.values(fields).forEach(field => {
							x.other.cf[field.id] = field.defaultAnswer
						})
					})
				}
				this.newTime = Date.now() + ''
			},
			deep: true
		},
	},
	computed: {
		unitGroup() {
			let unitGroupID = this.newElevation.id && this.newElevation.unitGroup
			if (typeof unitGroupID !== 'string') return this.$store.state.models.newModelTemp
			return this.$store.state.models.unitGroups[unitGroupID]
		},
		otherElevations() {
			let units = this.unitGroup && this.unitGroup.units || []
			return !this.newElevation.id ? units : units.filter(unit => unit.id !== this.newElevation.id)
		},
		fields() {
			if (this.$store.state.models.allSettings && this.$store.state.models.allSettings.app && this.$store.state.models.allSettings.app.options && this.$store.state.models.allSettings.app.options.customFields) {
				let fields = {}
				this.$store.state.models.allSettings.app.options.customFields.forEach(x => {
					fields[x.id] = x
					fields[x.id].value = x.type === 'number' ? Number(x.defaultAnswer) : x.type === 'date' ? moment(x.defaultAnswer) : x.defaultAnswer
				})
				let ids = Object.keys(fields)
				this.newElevationPackages.forEach(pane => {
					if (pane.other.cf) {
						let cf = Object.keys(pane.other.cf)
						let notAdded = ids.filter(x => !cf.includes(x))
						notAdded.forEach(id => {
							pane.other.cf[id] = fields[id].type === 'number' ? Number(fields[id].defaultAnswer) : fields[id].type === 'date' ? moment(fields[id].defaultAnswer) : fields[id].defaultAnswer
						})
					}
				})

				return fields
			}
			return {};
		},
		tags() {
			return this.$store.state.models.tags
		},
		instance() {
			return this.$store.state.instance
		},
		appData() {
			return this.$store.state.appData
		},
		getTitle() {
			return `Create a new `
		},
		dialog() {
			return this.$store.state.models.elevationDialog
		},
		elevations() {
			if (!this.$route.params.id) return []
			return Object.values(this.$store.state.models.units).filter(x => x.unitGroup).filter(x => x.unitGroup.id ? x.unitGroup.id === this.$route.params.id : x.unitGroup === this.$route.params.id)
		}
	},
	methods: {
		moment,

		hideFloorplan(pane) {
			pane.floors[0].name = 'N/A'
			pane.floors[0].image = ''
		},
		showFloorplan(pane) {
			pane.floors[0].name = ''
		},
		fieldChanged() {
			this.updateField = Date.now()
		},
		formats(e) {
			if (e != undefined) {
				if (!this.newElevation.media) {
					this.newElevation.media = {
						formats: e
					}
				} else if (!this.newElevation.media.formats) {
					this.newElevation.media.formats = e
				} else if (this.newElevation.media && this.newElevation.media.formats) this.newElevation.media.formats = e
			}
		},
		applyMulField(pane, e, id) {
			this.newElevationPackages.forEach(x => {
				if (x.key == pane.key) {
					x.other.cf[id] = e
				}
			})
		},

		changeCheck(pane, e, id) {
			this.newElevationPackages.forEach(x => {
				if (x.key == pane.key) {
					x.other.cf[id] = e.target.value;
				}
			})
			this.updateField = Date.now()
        },

		deleteElevation() {
			let self = this
			if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')
			this.$confirm({
				title: "Delete",
				content: h => <div>Do you want to delete Elevation. Deleting will permanently remove the Elevation</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					self.confirmDelete()
				}
			})
		},
		req(msg, required = true) {
			return { required: required, message: msg }
		},
		returnFloor(floor) {
			this.floorMain = floor
		},
		selectedImg(item) {
			if (typeof item === 'string') item = { url: item }
			this.newElevation.image = item.url
		},
		selectImg(data) {
			if (!data.includes('https')) {
				data = 'https://' + data
			}
			this.newElevation.image = data
		},
		selectImg2(data) {
			if (!data.includes('https')) {
				data = 'https://' + data
			}
			this.newElevation.image2 = data
		},
		selectedPdf(item) {
			if (typeof item === 'string') item = { url: item }
			this.newElevation.pdf = item.url
			this.fileType = mime.lookup(item.url)
		},
		selectedFeatures(item) {
			if (typeof item === 'string') item = { url: item }
			this.newElevation.features = item.url
			this.fileType = mime.lookup(item.url)
		},
		selectedSalesSheet(item) {
            if (typeof item === 'string') item = { url: item }
            this.newElevation.salesSheet = item.url
            this.fileType = mime.lookup(item.url)
        },
		selectPdf() {
			this.$store.commit('MEDIA_SELECT', { callback: this.selectedPdf, type: 'pdf' })
		},
		selectFeatures() {
			this.$store.commit('MEDIA_SELECT', { callback: this.selectedFeatures, type: 'pdf' })
		},
		selectSalesSheet() {
            this.$store.commit('MEDIA_SELECT', { callback: this.selectedSalesSheet, type: 'pdf' })
        },
		selectedFloorImage(item, floor) {
			if (!item.includes('https')) {
				item = "https://" + item
			}
			floor.image = item
		},
		addField() {
			this.$store.commit('ADD_FIELD')
		},
		removeField(index) {
			if (index != -1) {
				this.newElevation.customFields.splice(index, 1)
			}
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					alert('submit!');
				} else {
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		removeDomain(item) {
			let index = this.dynamicValidateForm.domains.indexOf(item);
			if (index !== -1) {
				this.dynamicValidateForm.domains.splice(index, 1);
			}
		},
		addDomain() {
			this.dynamicValidateForm.domains.push({
				value: '',
				key: Date.now(),
			});
		},
		confirmDelete() {
			this.deleting = true
			this.$api.delete(`/units/${this.instance.id}/${this.newElevation.id}`).then(({ data }) => {
				this.onClose({ type: 'elevation---delete', data })
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},
		submitButton() {
			if (this.dialog.type === 'add') return this.createElevation()
			return this.updateElevation()
		},
		deleteNewFloor(pane, delOrder) {
			this.newElevationPackages.forEach(x => {
				if (x.key == pane.key) {
					x.floors.splice(delOrder, 1)
				}
			})
		},
		addFloor(pane) {
			let errFloor = []
			pane.floors.forEach(floor => {
				if (floor.name != 'N/A' && (floor.name == '' || floor.image == '')) {
					errFloor.push(floor)
				}
			})

			if (errFloor.length > 0) return this.$message.error('Please fill in all your floors first!')

			let newOrder = pane.floors.length
			pane.floors.push({
				name: '',
				order: newOrder,
				image: ''
			})
			this.openNewFloor = pane.key + newOrder
		},
		tagsToIds(elev) {
			let tags = elev.tags || []

			elev.newTags = []
			let oldTags = []

			let self = this

			tags.forEach(tID => {
				if (Object.values(self.tags).find(x => x.name === tID)) {
					let found = Object.values(self.tags).find(x => x.name === tID)
					oldTags.push(found.id)
				}
				else elev.newTags.push(tID)
			})
			elev.tags = oldTags
			return elev

		},
		async createElevation() {
			if (!this.newElevation.name.trim()) return this.$message.error('Elevation Needs a Name')
			if (!this.newElevation.image.trim()) return this.$message.error('Elevation Needs an Image')

			if (this.newElevation.name && this.newElevation.name.length) {

				let elevationList = this.elevations.map(x => x = x.name.toLowerCase().trim())
				if (elevationList.includes(this.newElevation.name.toLowerCase().trim())) {
					let self = this
					this.$confirm({
						title: "Duplicate Elevation",
						content: h => `Elevation ${self.newElevation.name} already exists. Are you sure you want to continue?`,
						okText: 'Create',
						okType: 'danger',
						cancelText: 'Cancel',
						centered: true,
						onOk() {
							self.confirmCreateElevation()
						}
					})
				} else {
					this.confirmCreateElevation()
				}
			}
		},
		async confirmCreateElevation() {
			for (var i = 0; i < this.newElevation.customFields.length; i++) {
				if (this.newElevation.customFields[i].key == '' && this.newElevation.customFields[i].value == '' && i != 0) {
					this.newElevation.customFields.splice(i, 1)
				}
			}

			let sendObj = JSON.parse(JSON.stringify(this.newElevation))

			sendObj.name = sendObj.name && sendObj.name.trim()

			sendObj.packages = JSON.parse(JSON.stringify(this.newElevationPackages))
			sendObj.packages.forEach(x => {
				if (x.dens == null) x.dens = 0
			})

			let packError = sendObj.packages.find(pack => {
				if (!pack.name || !pack.name.trim()) return true
				pack.name = pack.name.trim()
				return Boolean(pack.floors.find(floor => {
					return floor.name != 'N/A' && (!floor.name || !floor.name.trim() || !floor.image || !floor.image.trim())
				}))
			})
			if (packError) return this.$message.error('Packages have error. Please check your fields.')

			sendObj = this.tagsToIds(sendObj)
			sendObj.buildingType = "models"
			sendObj.instance = this.instance.id


			let isNewModel = this.$route.meta.newModel

			if (isNewModel) {

				let newModel = this.$store.state.models.newModelTemp
				try {
					let { data: modelData } = await this.$api.post(`/units/${this.instance.id}/unitgroups`, newModel)
					this.$store.commit('SET_PROP', { where: ['unitGroups', modelData.id], what: modelData })

					sendObj.unitGroup = modelData.id
					this.loading = true
					this.$api.post(`/units/${this.instance.id}`, sendObj).then(({ data }) => {
						this.loading = false
						this.onClose({ type: 'elevation--add', data })
					}).catch(err => {
						this.loading = false
					})
				} catch (err) {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}
			} else {
				sendObj.unitGroup = this.$store.state.models.newModelTemp.id
				this.loading = true
				this.$api.post(`/units/${this.instance.id}`, sendObj).then(({ data }) => {
					this.loading = false
					this.onClose({ type: 'elevation--add', data })
				}).catch(err => {
					this.loading = false
				})
			}
		},
		updateElevation() {
			if (!this.newElevation.name || !this.newElevation.name.trim()) return this.$message.error('Elevation Needs a Name')
			if (!this.newElevation.image || !this.newElevation.image.trim()) return this.$message.error('Elevation Needs an Image')

			if (this.newElevation.name && this.newElevation.name.length) {

				let elevationList = this.elevations.filter(x => x.id != this.newElevation.id).map(x => x = x.name && x.name.toLowerCase().trim())
				if (elevationList.includes(this.newElevation.name.toLowerCase().trim())) {
					let self = this
					this.$confirm({
						title: "Duplicate Elevation",
						content: h => `Elevation ${self.newElevation.name} already exists. Are you sure you want to continue?`,
						okText: 'Update',
						okType: 'danger',
						cancelText: 'Cancel',
						centered: true,
						onOk() {
							self.confirmUpdateElevation()
						}
					})
				} else {
					this.confirmUpdateElevation()
				}
			}
		},
		confirmUpdateElevation() {
			for (var i = 0; i < this.newElevation.customFields.length; i++) {
				if (!this.newElevation.customFields[i].key.trim() && !this.newElevation.customFields[i].value.trim() && i != 0) {
					this.newElevation.customFields.splice(i, 1)
				}
			}

			let sendObj = JSON.parse(JSON.stringify(this.newElevation))
			sendObj.packages = JSON.parse(JSON.stringify(this.newElevationPackages))

			sendObj.packages.forEach(x => {
				if (x.dens == null) x.dens = 0
			})

			let packError = sendObj.packages.find(pack => {

				if (!pack.name || !pack.name.trim()) return true

				return Boolean(pack.floors.find(floor => {
					return floor.name != 'N/A' && (!floor.name || !floor.name.trim() || !floor.image || !floor.image.trim())
				}))
			})
			if (packError) return this.$message.error('Packages have error. Please check your fields.')

			sendObj = this.tagsToIds(sendObj)

			sendObj.buildingType = "models"
			sendObj.instance = this.instance.id
			this.loading = true
			this.$api.put(`/units/${this.instance.id}/${sendObj.id}`, sendObj).then(({ data }) => {
				this.loading = false
				this.$store.commit('UPDATE_ELEVATION', data)
				this.onClose({ type: 'elevation--edit', data })
			}).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},
		onClose({ type = '', data = {} }) {
			document.querySelector(`.ant-drawer-wrapper-body`).scrollTop = 0
			this.$store.dispatch('closeDialog', { type, data })
			this.$refs.newElevation.resetFields()
		},
		onEditNewElevationPackage(targetKey, action) {
			if (action == 'remove') {
				this.activeKey = '1'
			}
			this.newElevationPackages = this.newElevationPackages.filter(x => x.key !== targetKey)
		},
		addNewElevationPackage() {

			let timeNow = Date.now() + ''
			const activeKey = timeNow
			let newLength = this.newElevationPackages.length + 1
			let prevObj = JSON.parse(JSON.stringify(this.newElevationPackages[this.newElevationPackages.length - 1]))
			let differentFields = []

			Object.keys(packageDetails).forEach(key => {
				if (prevObj[key] != packageDetails[key] && (key != 'floors' && key != 'finishedBasement')) {
					differentFields.push(key)
				}
			})

			if (differentFields.length) {
				let newPackage = {}
				newPackage = {
					stories: prevObj.stories,
					garages: prevObj.garages,
					sqft: prevObj.sqft,
					sqft2: prevObj.sqft2,
					beds: prevObj.beds,
					dens: prevObj.dens,
					baths: prevObj.baths,
					powderRooms: prevObj.powderRooms,
					finishedBasement: false,
					hasLoft: false,
					price: prevObj.price,
					floors: [
						{
							name: '',
							order: -1,
							image: ''
						}
					]
				}
				if (prevObj.floors && prevObj.floors.length){
					newPackage.floors = []
					prevObj.floors.forEach(x => {
						newPackage.floors.push({
							name: x.name,
							order: x.order,
							image: x.image
						})
					})
				}
				this.newElevationPackages.push({
					name: `Optional Floor Plan`,
					key: activeKey,
					...JSON.parse(JSON.stringify(newPackage)),
					other: { cf: {} }
				});
			} else {
				this.newElevationPackages.push({
					name: `Optional Floor Plan`,
					key: activeKey,
					...JSON.parse(JSON.stringify(packageDetails)),
					other: { cf: {} }
				});
			}
			this.activeKey = activeKey;
		},
		removeNewElevationPackage(targetKey) {
			let activeKey = this.activeKey;
			let lastIndex;
			this.newElevationPackages.forEach((pane, i) => {
				if (pane.key === targetKey) {
					lastIndex = i - 1;
				}
			});
			const panes = this.newElevationPackages.filter(pane => pane.key !== targetKey);
			if (panes.length && activeKey === targetKey) {
				if (lastIndex >= 0) {
					activeKey = panes[lastIndex].key;
				} else {
					activeKey = panes[0].key;
				}
			}
			this.newElevationPackages = panes;
			this.activeKey = activeKey;
		},
	},
};
</script>

<style scoped>
.dynamic-delete-button {
	cursor: pointer;
	transition: all 0.3s;
}

.dynamic-delete-button:hover {
	color: black;
}

.dynamic-delete-button[disabled] {
	cursor: not-allowed;
	opacity: 0.5;
}
</style>
<style>
.elevation-hide-scrollbar .ant-drawer-wrapper-body::-webkit-scrollbar {
	display: none;
}

.cancel-button.ant-btn {
	border-color: #ECE9F1 !important;
	background-color: #ECE9F1;
	color: #3F3356
}

.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner {
	background: #3F3356;
}
</style>
